import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a62adefe = () => interopDefault(import('../pages/c2m/index.vue' /* webpackChunkName: "pages/c2m/index" */))
const _80decab4 = () => interopDefault(import('../pages/helpcenter.vue' /* webpackChunkName: "pages/helpcenter" */))
const _6533a852 = () => interopDefault(import('../pages/helpcenter/acceptPay.vue' /* webpackChunkName: "pages/helpcenter/acceptPay" */))
const _5da4a251 = () => interopDefault(import('../pages/helpcenter/c2mRule.vue' /* webpackChunkName: "pages/helpcenter/c2mRule" */))
const _bbe281d8 = () => interopDefault(import('../pages/helpcenter/company.vue' /* webpackChunkName: "pages/helpcenter/company" */))
const _7a67c592 = () => interopDefault(import('../pages/helpcenter/contact.vue' /* webpackChunkName: "pages/helpcenter/contact" */))
const _a291d354 = () => interopDefault(import('../pages/helpcenter/contact/salesman.vue' /* webpackChunkName: "pages/helpcenter/contact/salesman" */))
const _be706246 = () => interopDefault(import('../pages/helpcenter/contact/service.vue' /* webpackChunkName: "pages/helpcenter/contact/service" */))
const _045e702a = () => interopDefault(import('../pages/helpcenter/contact/warehouse.vue' /* webpackChunkName: "pages/helpcenter/contact/warehouse" */))
const _30f811f6 = () => interopDefault(import('../pages/helpcenter/internetbank.vue' /* webpackChunkName: "pages/helpcenter/internetbank" */))
const _1c382b8a = () => interopDefault(import('../pages/helpcenter/privacyRule.vue' /* webpackChunkName: "pages/helpcenter/privacyRule" */))
const _2c68736a = () => interopDefault(import('../pages/helpcenter/registRule.vue' /* webpackChunkName: "pages/helpcenter/registRule" */))
const _aeab164c = () => interopDefault(import('../pages/helpcenter/saleTemplate.vue' /* webpackChunkName: "pages/helpcenter/saleTemplate" */))
const _2c42d554 = () => interopDefault(import('../pages/helpcenter/sellerRule.vue' /* webpackChunkName: "pages/helpcenter/sellerRule" */))
const _a688d1e0 = () => interopDefault(import('../pages/helpcenter/trendRule.vue' /* webpackChunkName: "pages/helpcenter/trendRule" */))
const _1800ae18 = () => interopDefault(import('../pages/helpcenter/layout/siderBar.vue' /* webpackChunkName: "pages/helpcenter/layout/siderBar" */))
const _ccf18eb2 = () => interopDefault(import('../pages/helpcenter/layout/siderBarItem.vue' /* webpackChunkName: "pages/helpcenter/layout/siderBarItem" */))
const _df1205e8 = () => interopDefault(import('../pages/helpcenter/buyerGuide/_type.vue' /* webpackChunkName: "pages/helpcenter/buyerGuide/_type" */))
const _2548bc90 = () => interopDefault(import('../pages/helpcenter/processGuide/_type.vue' /* webpackChunkName: "pages/helpcenter/processGuide/_type" */))
const _00b8d91e = () => interopDefault(import('../pages/helpcenter/sellerGuide/_type.vue' /* webpackChunkName: "pages/helpcenter/sellerGuide/_type" */))
const _265ce32a = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _fba9bb34 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _16517e2b = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _17af26d9 = () => interopDefault(import('../pages/oddments/index.vue' /* webpackChunkName: "pages/oddments/index" */))
const _20129158 = () => interopDefault(import('../pages/questionaire/index.vue' /* webpackChunkName: "pages/questionaire/index" */))
const _8a643a6e = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _4bb14363 = () => interopDefault(import('../pages/specialPrice/index.vue' /* webpackChunkName: "pages/specialPrice/index" */))
const _30a8c5cc = () => interopDefault(import('../pages/transport/index.vue' /* webpackChunkName: "pages/transport/index" */))
const _130406b9 = () => interopDefault(import('../pages/wantBuy/index.vue' /* webpackChunkName: "pages/wantBuy/index" */))
const _5a63097e = () => interopDefault(import('../pages/bid/pack/index.vue' /* webpackChunkName: "pages/bid/pack/index" */))
const _30c67b26 = () => interopDefault(import('../pages/bid/single/index.vue' /* webpackChunkName: "pages/bid/single/index" */))
const _b44f84ae = () => interopDefault(import('../pages/c2m/download.vue' /* webpackChunkName: "pages/c2m/download" */))
const _e07c89f6 = () => interopDefault(import('../pages/contract/merchant.vue' /* webpackChunkName: "pages/contract/merchant" */))
const _9a3d7998 = () => interopDefault(import('../pages/contract/threadSteel.vue' /* webpackChunkName: "pages/contract/threadSteel" */))
const _e7404ee2 = () => interopDefault(import('../pages/demo/allSvg.vue' /* webpackChunkName: "pages/demo/allSvg" */))
const _0ea50a6c = () => interopDefault(import('../pages/shop/carts.vue' /* webpackChunkName: "pages/shop/carts" */))
const _f517af0c = () => interopDefault(import('../pages/shop/cashier.vue' /* webpackChunkName: "pages/shop/cashier" */))
const _3e0f6f4d = () => interopDefault(import('../pages/shop/goods.vue' /* webpackChunkName: "pages/shop/goods" */))
const _1d374bff = () => interopDefault(import('../pages/c2m/components/factoryApp.vue' /* webpackChunkName: "pages/c2m/components/factoryApp" */))
const _268f1884 = () => interopDefault(import('../pages/c2m/components/userApp.vue' /* webpackChunkName: "pages/c2m/components/userApp" */))
const _347f7452 = () => interopDefault(import('../pages/c2m/contract/buyer.vue' /* webpackChunkName: "pages/c2m/contract/buyer" */))
const _3b6542a4 = () => interopDefault(import('../pages/c2m/contract/factory.vue' /* webpackChunkName: "pages/c2m/contract/factory" */))
const _0ae3bc70 = () => interopDefault(import('../pages/contract/template/theadTemp.vue' /* webpackChunkName: "pages/contract/template/theadTemp" */))
const _53cd3a1e = () => interopDefault(import('../pages/oddments/components/searchModel.vue' /* webpackChunkName: "pages/oddments/components/searchModel" */))
const _79048d1f = () => interopDefault(import('../pages/shop/components/c2mRequireDialog.vue' /* webpackChunkName: "pages/shop/components/c2mRequireDialog" */))
const _1ab660e6 = () => interopDefault(import('../pages/shop/components/goodsTableList.vue' /* webpackChunkName: "pages/shop/components/goodsTableList" */))
const _24e87f19 = () => interopDefault(import('../pages/shop/components/spanBadge.vue' /* webpackChunkName: "pages/shop/components/spanBadge" */))
const _2a03d4ac = () => interopDefault(import('../pages/shop/components/tableList.vue' /* webpackChunkName: "pages/shop/components/tableList" */))
const _5c92a8b2 = () => interopDefault(import('../pages/specialPrice/components/searchModel.vue' /* webpackChunkName: "pages/specialPrice/components/searchModel" */))
const _6142a644 = () => interopDefault(import('../pages/wantBuy/components/goodsList.vue' /* webpackChunkName: "pages/wantBuy/components/goodsList" */))
const _f129e412 = () => interopDefault(import('../pages/wantBuy/components/goodsListItem.vue' /* webpackChunkName: "pages/wantBuy/components/goodsListItem" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f4fd5934 = () => interopDefault(import('../pages/bid/pack/_id.vue' /* webpackChunkName: "pages/bid/pack/_id" */))
const _733b07d6 = () => interopDefault(import('../pages/bid/single/_id.vue' /* webpackChunkName: "pages/bid/single/_id" */))
const _4a6af364 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _b421e15a = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _a9f46998 = () => interopDefault(import('../pages/transport/_id.vue' /* webpackChunkName: "pages/transport/_id" */))
const _766bef7c = () => interopDefault(import('../pages/bcs/_.vue' /* webpackChunkName: "pages/bcs/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/c2m",
    component: _a62adefe,
    name: "c2m"
  }, {
    path: "/helpcenter",
    component: _80decab4,
    name: "helpcenter",
    children: [{
      path: "acceptPay",
      component: _6533a852,
      name: "helpcenter-acceptPay"
    }, {
      path: "c2mRule",
      component: _5da4a251,
      name: "helpcenter-c2mRule"
    }, {
      path: "company",
      component: _bbe281d8,
      name: "helpcenter-company"
    }, {
      path: "contact",
      component: _7a67c592,
      name: "helpcenter-contact",
      children: [{
        path: "salesman",
        component: _a291d354,
        name: "helpcenter-contact-salesman"
      }, {
        path: "service",
        component: _be706246,
        name: "helpcenter-contact-service"
      }, {
        path: "warehouse",
        component: _045e702a,
        name: "helpcenter-contact-warehouse"
      }]
    }, {
      path: "internetbank",
      component: _30f811f6,
      name: "helpcenter-internetbank"
    }, {
      path: "privacyRule",
      component: _1c382b8a,
      name: "helpcenter-privacyRule"
    }, {
      path: "registRule",
      component: _2c68736a,
      name: "helpcenter-registRule"
    }, {
      path: "saleTemplate",
      component: _aeab164c,
      name: "helpcenter-saleTemplate"
    }, {
      path: "sellerRule",
      component: _2c42d554,
      name: "helpcenter-sellerRule"
    }, {
      path: "trendRule",
      component: _a688d1e0,
      name: "helpcenter-trendRule"
    }, {
      path: "layout/siderBar",
      component: _1800ae18,
      name: "helpcenter-layout-siderBar"
    }, {
      path: "layout/siderBarItem",
      component: _ccf18eb2,
      name: "helpcenter-layout-siderBarItem"
    }, {
      path: "buyerGuide/:type?",
      component: _df1205e8,
      name: "helpcenter-buyerGuide-type"
    }, {
      path: "processGuide/:type?",
      component: _2548bc90,
      name: "helpcenter-processGuide-type"
    }, {
      path: "sellerGuide/:type?",
      component: _00b8d91e,
      name: "helpcenter-sellerGuide-type"
    }]
  }, {
    path: "/message",
    component: _265ce32a,
    name: "message"
  }, {
    path: "/news",
    component: _fba9bb34,
    name: "news"
  }, {
    path: "/notice",
    component: _16517e2b,
    name: "notice"
  }, {
    path: "/oddments",
    component: _17af26d9,
    name: "oddments"
  }, {
    path: "/questionaire",
    component: _20129158,
    name: "questionaire"
  }, {
    path: "/shop",
    component: _8a643a6e,
    name: "shop"
  }, {
    path: "/specialPrice",
    component: _4bb14363,
    name: "specialPrice"
  }, {
    path: "/transport",
    component: _30a8c5cc,
    name: "transport"
  }, {
    path: "/wantBuy",
    component: _130406b9,
    name: "wantBuy"
  }, {
    path: "/bid/pack",
    component: _5a63097e,
    name: "bid-pack"
  }, {
    path: "/bid/single",
    component: _30c67b26,
    name: "bid-single"
  }, {
    path: "/c2m/download",
    component: _b44f84ae,
    name: "c2m-download"
  }, {
    path: "/contract/merchant",
    component: _e07c89f6,
    name: "contract-merchant"
  }, {
    path: "/contract/threadSteel",
    component: _9a3d7998,
    name: "contract-threadSteel"
  }, {
    path: "/demo/allSvg",
    component: _e7404ee2,
    name: "demo-allSvg"
  }, {
    path: "/shop/carts",
    component: _0ea50a6c,
    name: "shop-carts"
  }, {
    path: "/shop/cashier",
    component: _f517af0c,
    name: "shop-cashier"
  }, {
    path: "/shop/goods",
    component: _3e0f6f4d,
    name: "shop-goods"
  }, {
    path: "/c2m/components/factoryApp",
    component: _1d374bff,
    name: "c2m-components-factoryApp"
  }, {
    path: "/c2m/components/userApp",
    component: _268f1884,
    name: "c2m-components-userApp"
  }, {
    path: "/c2m/contract/buyer",
    component: _347f7452,
    name: "c2m-contract-buyer"
  }, {
    path: "/c2m/contract/factory",
    component: _3b6542a4,
    name: "c2m-contract-factory"
  }, {
    path: "/contract/template/theadTemp",
    component: _0ae3bc70,
    name: "contract-template-theadTemp"
  }, {
    path: "/oddments/components/searchModel",
    component: _53cd3a1e,
    name: "oddments-components-searchModel"
  }, {
    path: "/shop/components/c2mRequireDialog",
    component: _79048d1f,
    name: "shop-components-c2mRequireDialog"
  }, {
    path: "/shop/components/goodsTableList",
    component: _1ab660e6,
    name: "shop-components-goodsTableList"
  }, {
    path: "/shop/components/spanBadge",
    component: _24e87f19,
    name: "shop-components-spanBadge"
  }, {
    path: "/shop/components/tableList",
    component: _2a03d4ac,
    name: "shop-components-tableList"
  }, {
    path: "/specialPrice/components/searchModel",
    component: _5c92a8b2,
    name: "specialPrice-components-searchModel"
  }, {
    path: "/wantBuy/components/goodsList",
    component: _6142a644,
    name: "wantBuy-components-goodsList"
  }, {
    path: "/wantBuy/components/goodsListItem",
    component: _f129e412,
    name: "wantBuy-components-goodsListItem"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/bid/pack/:id",
    component: _f4fd5934,
    name: "bid-pack-id"
  }, {
    path: "/bid/single/:id",
    component: _733b07d6,
    name: "bid-single-id"
  }, {
    path: "/news/:id",
    component: _4a6af364,
    name: "news-id"
  }, {
    path: "/notice/:id",
    component: _b421e15a,
    name: "notice-id"
  }, {
    path: "/transport/:id",
    component: _a9f46998,
    name: "transport-id"
  }, {
    path: "/bcs/*",
    component: _766bef7c,
    name: "bcs-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
